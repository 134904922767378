.contactWrapper{
    position: relative;
    min-height: 60vh;
}
.contactUs{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 1rem;
    /* position: relative; */
    /* overflow-y: hidden; */
}
.contactUs main{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 4rem 0rem;
}
.contactUs main h1{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s7);
    color: var(--dark);
    margin-bottom: 1.5rem;
}
.contactUs main p{
    font-family: var(--f2);
    font-weight: var(--w1);
    font-size: var(--s3);
    color: var(--dark);
}
.contactUs main p span{
    font-family: var(--f2);
    font-weight: var(--w2);
    font-size: var(--s3);
    color: var(--dark);
}
.contactUs aside p{
    font-family: var(--f2);
    font-weight: var(--w2);
    font-size: var(--s3);
    color: var(--dark);
    padding: 2rem;
    margin-bottom: 5rem;
}
.contactDecor{
    width: 100vw;
    height: 100vh;
    position: absolute;
    overflow: hidden;
    pointer-events: none;
}
.contactDecor img{
    width: 65%;
    position: absolute;
    right: -25%;
    bottom: -75%;
    opacity: .3;
    z-index: 0;
}
/* =========================================================Mobile */
@media screen and (max-width:768px) {
    .contactMargin{
        margin-bottom: 1.5rem;
    }
    .contactUs aside p{
        padding: 0;
        font-weight: var(--w1);
        margin-bottom: 10rem;
    }
}