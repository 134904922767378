.successHeader{
    /* overflow: hidden; */
    position: relative;
}
.successMain{
    align-items: center !important;
}
.successAside{
    width: 100%;
    text-align: center;
}
/* =========================================================Mobile */
@media screen and (max-width:768px){
    .successNote{
        font-size: var(--s6) !important;
    }
    .successNote2 p{
        font-size: var(--s2) !important;
    }   
}
/* =====================================Loading======================= */
.sucessBG{
    opacity: .8;
}
.sucess{
    justify-content: flex-end;
    flex-direction: row;
}
.sucess p{
    margin-top: 0;
}
.loadingAnim{
    width: 35%;
}
.loadingAnim::after{
    content: ".";
    animation: loading 1s linear infinite;
}

@keyframes loading {
    0%{
        content: ".";
    }
    50%{
        content: "..";
    }
    100%{
        content: "...";
    }
}