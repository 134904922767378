.loginPage{
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.loginPage h1{
    color: var(--dark);
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s7);
    text-align: center;
    margin-bottom: 1rem;
}
.loginPage form{
    width: 300px;
    padding: 2.5rem 1rem 2rem 1rem;
    border-radius: 1rem;
    background-color: var(--white);
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}
.inputAuth{
    width: 100%;
    margin-bottom: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.inputAuth input{
    width: 100%;
    padding: .5rem;
    border-radius: .5rem;
    background-color: var(--light);
    border: none;
    outline: none;
    color: var(--dark);
    text-align: center;
}
.inputAuth input::placeholder{
    color: var(--gold);
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s3);
    text-align: center;
}
.inputAuth input:focus{
    border: 2px solid var(--gold);
}
.inputAuth input[type='submit']{
    margin-top: 1rem;
    width: 50%;
    background-color: var(--gold);
    color: var(--dark);
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s3);
}