.navWebContainer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: .5rem 10%;
    padding-top: 1rem;
    border-bottom: 1.5px solid var(--dark);
}
.navWebContainer aside{
    margin-right: auto;
}
.navWebContainer h1{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s7);
    color: var(--dark);
    line-height: 30px;
}
.navWebContainer p{
    font-family: var(--f2);
    font-weight: var(--w3);
    font-size: var(--s2);
    color: var(--dark);
}
.navWebContainer ul{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;
    list-style-type: none;
}
.navWebContainer ul li a{
    text-decoration: none;
    font-family: var(--f2);
    font-weight: var(--w3);
    font-size: var(--s2);
    color: var(--dark);
}