.formPage{
    width: 100%;
    margin: 1rem 0;
}
.formContainer{
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: var(--white);
    padding: 2rem 3rem;
    margin-bottom: 5rem;
}
.formContainer form{
    width: 45%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.formContainer form h1{
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s9);
    color: var(--dark);
    margin-bottom: 1.5rem;
}
.inputContainer{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 1rem;
}
.inputContainer label{
    font-family: var(--f2);
    font-weight: var(--w3);
    font-size: var(--s2);
    color: var(--dark);
    margin-bottom: .5rem;
}
.inputContainer input, .inputContainer select{
    width: 100%;
    font-family: var(--f2);
    font-weight: var(--w1);
    font-size: var(--s3);
    color: var(--dark);
    padding: .35rem .5rem;
    border: none;
    background-color: var(--input);
    border-radius: 5px;
}
.time{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.time select{
    width: 45%;
}
.placeRow{
    width: 100%;
    padding-left: 1rem;
    display: flex;
    justify-content: space-between;
}
.place{
    width: 45%;
    margin-bottom: 1rem;
}
.country{
    width: 100%;
}
.checkbox{
    flex-direction: row;
    justify-content: flex-start;
}
.checkbox input{
    width: auto;
    margin-right: .5rem;    
    accent-color: var(--input);
}
.checkbox label{
    font-size: var(--s1);
}
.inputContainer input[type=submit]{
    background-color: var(--gold);
    color: var(--white);
    font-weight: var(w1);
    cursor: pointer;
}
.inputContainer input[type=submit]:active{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
/* ================================================================ */
.formContainer figure{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-top: 5rem;
    opacity: .5;
}
.formContainer figure img{
    width: 100%;
    object-fit: cover;
    object-position: center;
}
/* =========================================================Mobile */
@media screen and (max-width:768px) {
    .formContainer figure{
        display: none;
    }
    .formContainer{
        width: 100%;
        margin-top: 2rem;
        padding: 2rem 1.5rem;
    }
    .formContainer form{
        width: 100%;
    }
    .formContainer form h1{
        font-weight: var(--w1);
        font-size: var(--s6);
    }
}
/* =======================loading====================== */
.estloadingContainer{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
}
.estloadingBG{
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .5;
    position: absolute;
    z-index: 5;
}
.estloading{
    width: 10rem;
    height: 10rem;
    background-color: var(--light);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 6;
    border-radius: 2rem;
    padding: .5rem;
    /* -webkit-animation: rotate 4s linear infinite; */
}
.estloading img{
    width: 50%;
    object-fit: contain;
    object-position: center;
    animation: rotate 4s linear infinite;
}
.estloading p{
    font-family: var(--f2);
    font-weight: var(--w3);
    font-size: var(--s3);
    color: var(--dark);
    margin-top: 1rem;
}
@keyframes rotate {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}