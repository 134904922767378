.footerContainer{
    width: 100%;
    background-color: var(--gold);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 4rem 7rem 2rem 7rem;
    position: relative;
    z-index: 1;
}
.footercol{
    width: 30%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.mainFooter{
    width: 50%;
}
.footercol header h1{
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s9);
    color: var(--white );
    line-height: 30px;
}
.footercol header h2{
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s6);
    color: var(--white );
}
.footercol header, .footercol main{
    margin-bottom: 1rem;
}
.footercol main h3{
    font-family: var(--f2);
    font-weight: var(--w1);
    font-size: var(--s5);
    color: var(--white );
}
.footercol main p{
    font-family: var(--f2);
    font-weight: var(--w1);
    font-size: var(--s3);
    color: var(--white );
}
.footercol figure{
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
}
.footercol figure img{
    width: 35px;
}
.footerMenu{
    width: 20%;
}
.footerMenu ul{
    list-style-type: none;
}
.footerMenu ul li{
    font-family: var(--f2);
    font-weight: var(--w3);
    font-size: var(--s2);
    margin-bottom: 1rem;
}
.footerMenu ul li a{
    text-decoration: none;
    color: var(--white );
}
.footercol article h3{
    font-family: var(--f2);
    font-weight: var(--w1);
    font-size: var(--s5);
    color: var(--white );
}
.footercol article p{
    font-family: var(--f2);
    font-weight: var(--w1);
    font-size: var(--s3);
    color: var(--white );
}
.footercol article{
    margin-bottom: 3rem;
}
.hide{
    display: none !important;
}
.show{
    display: block;
}
.termRow{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.termRow a{
    text-decoration: none;
    color: var(--white);
    margin-bottom: .25rem;
    cursor: pointer;
}

/* ===================================================== Mobile */
@media screen and (max-width:768px) {
    .footerContainer{
        flex-direction: column;
        padding: 2rem 1rem;
    }
    .footercol{
        width: 100%;
    }
    .mainFooter, .footerMenu{
        border-bottom: 2px solid var(--dark);
        margin-bottom: 1rem;
    }
    .footercol header{
        margin-bottom: 2rem;
    }
    .footercol header h1{
        font-weight: var(--w1);
        font-size: var(--s7);
    }
    .footercol header h2{
        font-weight: var(--w1);
        font-size: var(--s3);
    }
    .footercol article{
        margin-bottom: 1rem;
    }
    .footercol figure{
        margin-bottom: 4rem;
    }
    .footercol article h3{
        font-weight: var(--w1);
        font-size: var(--s4);
    }
    .footercol article p{
        font-weight: var(--w1);
        font-size: var(--s2);
    }
    .termRow{
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        gap: 2rem;
        width: 100%;
        margin-bottom: 1rem;
        font-size: var(--s1);
    }
}