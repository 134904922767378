.articleLanding{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 1rem;
}
.articleLanding main{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3rem 0;
}
.articleLanding main h1{
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s9);
    color: var(--dark);
}
/* =========================================== */
.articleContainer{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem 0;
}
/* =========================================================Mobile */
@media screen and (max-width:768px){
    .articleLanding main{
        flex-direction: column;
    }
    .articleLanding main figure{
        transform: rotate(90deg);
        height: 400px;
        display: flex;
        justify-content: flex-start;
    }
    .articleLanding main figure img{
        width: 100%;
        object-fit: contain;
        object-position: center;
    }
}