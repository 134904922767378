.nav_container_mob{
    width: 100dvw;
    padding-top: 1rem;
    /* position: fixed; */
    z-index: 4;
    padding-bottom: .5rem;
    transition: 1s;
    position: relative;
    background-color: var(--light);
    z-index: 5;
}
.nav_main_mob{
    width: 90%;
    margin-left: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav_main_mob .nav_logo{
    height: 45px;
}
.nav_main_mob .nav_logo a{
    text-decoration: none;
    color: var(--dark);
}
.nav_main_mob .nav_logo a h1{
    font-family: var(--f1);
    font-size: var(--s7);
    font-weight: var(--w1);
    line-height: 30px;
}
.nav_main_mob .nav_logo a p{
    font-family: var(--f2);
    font-size: var(--s2);
    font-weight: var(--w3);
    /* line-height: 16px; */
}
.nav_mob_menu_btn{
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.bar{
    width: 80%;
    height: 3.5px;
    background-color: var(--read);
    margin-bottom: 3.5px;
    border-radius: 50px;
    transition: all .5s;
}
.right{
    width: 50%;
    position: absolute;
    transform: rotate(-315deg);
}
.left{
    width: 50%;
    position: absolute;
    transform: rotate(315deg);
}
.opaZero{
    opacity: 0;
}
.nav_mob_menu{
    position: fixed;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 150dvh;
    background-color: var(--light);
    
}
.displayNone{
    display: none;
}
.nav_mob_menu_container{
    width: 100%;
    height: 65%;
    padding: 10rem 2rem 2rem 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
    position: relative;
}
.nav_mob_menu_container a{
    margin-bottom: 1rem;
    text-decoration: none;
    color: var(--dark);
    font-family: var(--f2);
    font-size: 1.5rem;
    font-weight: var(--w3);
    text-wrap: nowrap;
}
.mob_menu_logo{
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
}
.menuClicked{
    position: fixed;
    right: 0;
}
.Mobbg{
    opacity: .5;
}
.udLine{
    text-decoration: none;
    position: relative;
}
.udLine::after{
    content: '';

    width: 100%;
    position: absolute;
    left: 0;
    bottom: 1px;

    border-width: 0 0 3px;
    border-style: solid;
    border-color: var(--read);
}
/* ============================= Mobile ====================== */
@media screen and (max-width:768px) {
    .nav_mob_menu_container a{
        display: flex;
        flex-direction: row-reverse;
        gap: .5rem;
    }
}