.homeLander{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 1rem;
}
.phoneBtn{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 1;
}
.homeLander article{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 0;
}
.homeLander article header{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.homeLander article header h1{
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s10);
    color: var(--dark);
}
.homeLander article header h2{
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s10);
    color: var(--dark);
    line-height: 10px;
}
.homeLander article header h2{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s8);
    color: var(--dark);
    line-height: 10px;
}
.homeLander article header h3{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s7);
    color: var(--dark);
    line-height: 10px;
}
.homeLander article figure{
    width: 35%;
    position: relative;
}
.landingDecore{
    width: 100%;
}
/* If half size */
.landMeenatchi{
    width: 63%;
    position: absolute;
    top: 0;
    left: 48.75%;
    transform: translateX(-50%);
}
/* If full Size */
.landMeenatchi{
    width: 69%;
    position: absolute;
    top: -8%;
    left: 49%;
    transform: translateX(-50%);
}
.btnSpacing{
    margin-top: 2rem;
}
/* =========================================================Mobile */
@media screen and (max-width:768px) {
    .homeLander article{
        flex-direction: column-reverse;
    }
    .homeLander article figure{
        width: 100%;
        margin-bottom: 2rem;
    }
    .homeLander article header{
        align-items: center;
    }
    .homeLander article header h1{
        font-size: var(--s7);
    }
    .homeLander article header h2, .homeLander article header h3{
        font-weight: var(--w1);
        font-size: var(--s4);
        line-height: 10px;
    }
}
/* =============================== Sign Scroll ========================== */
.homeSignCorosal{
    width: 100vw;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    border: 2px solid var(--gold);
    padding: .5rem 0rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
}
.homeSignCorosal figure{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 3.5rem;
    padding:0  1.5rem;
    animation: slide 20s infinite linear ;
}
@keyframes slide {
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(-100%);
    }
}
/* =========================================================Mobile */
@media screen and (max-width:768px) {
    .homeSignCorosal figure{
        gap: 2rem;
    }
}
/* ========================================================================== */
.homeWelcome{
    width: 100%;
    height: 650px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1.5rem 0;
    position: relative;
    /* background-color: blue; */
}
.homeWelcome main{
    width: 55%;
}
.homeWelcome main header h2{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s7);
    color: var(--dark);
}
.homeWelcome main p{
    font-family: var(--f2);
    font-weight: var(--w1);
    font-size: var(--s3);
    color: var(--dark);
}
.homeWelcome figure{
    position: absolute;
    width: 650px;
    height: 650px;
    right: -325px;
}
.outer{
    height: 100%;
    object-position: center;
    object-fit: contain;
}
#parallax{
    width: 100%;
    height: 100%;
    position: absolute;
    right: -2%;
    top: 1%;
    transform: translateY(-50%);
}
.inner{
    height: 42%;
    object-position: center;
    object-fit: contain;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
/* =========================================================Mobile */
@media screen and (max-width:768px) {
    .homeWelcome{
        align-items: flex-start;
        flex-direction: column;
    }
    .homeWelcome main{
        width: 100%;
    }
    .homeWelcome figure{
        position: relative;
        width: 400px;
        height: 400px;
        right: -150px;
    }
    
}
/* ================================== Home Serivce ==================== */
.homeService{
    width: 100%;
    height: 750px;
    position: relative;
    overflow: hidden;
    margin: 2rem 0;
}
.homeServiceDecor {
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
}
.homeServiceDecor img{
    height: 500px;
    object-fit: contain;
} 
/* =========================================================Mobile */
@media screen and (max-width:768px) {
    .homeService{
        height: auto;
    }
}
/* ============================================================Notification */
.notificationContainer{
    position: fixed;
    top: 90%;
    right: 10%;
    z-index: 5;
}
.notificationIcon{    
    background-color: var(--white);
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    cursor: pointer;
    transition: .5s ease;
}
.notificationIcon img{
    object-fit: contain;
    width: 70%;
    object-position: center;
}
.showIcon{
    width: 40px;
    height: 40px;
    opacity: 1;
    bottom: 0;
    right: 0;
}
.hideIcon{
    width: 0;
    height: 0;
    opacity: 0;
    pointer-events: none;
    top: 0;
    right: 0;
}
.notification{
    border-radius: 25px;
    background-color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    transition: .5s ease;
    overflow: hidden;
}
.notification header{
    padding: .5rem 1.5rem;
    background-color: var(--notify);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.notification header h3{
    font-family: var(--f2);
    font-weight: var(--w3);
    font-size: var(--s3);
    color: var(--white);
}
.notification header img{
    cursor: pointer;
}
.notice{
    padding: .5rem 1.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: .5rem;
}
.notice article{
    width: 100%;
    margin-bottom: .5rem;
}
.notice article a{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-decoration: none;
    color: var(--notify);
}
.notice article a main{
    width: 80%;    
    overflow: hidden;
}
.notice article a main p{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.displayContainer{
    width: 250px;
    /* height: 300px;     */
    position: absolute;
    bottom: 0;
    right: 0;
}
.noDisplayContainer{
    width: 0;
    /* height: 0; */
    opacity: 0;
    pointer-events: none;
    position: absolute;
    bottom: 0;
    right: 0;
}