.serviceLanding{
    width: 100vw;
    padding: 1rem 10% 0 0;
    position: relative;
    height: 500px;
    overflow: hidden;
}
.serviceLanding figure{
    width: 100%;
    position: absolute;
    top: 0;
}
.serviceLanding figure img{
    width: 100%;
    object-fit: cover;
    mix-blend-mode: lighten;
}
.serviceLanding header{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.serviceLanding header h1{
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s9);
    color: var(--dark);
    text-align: center;
    line-height: 1;
}
.serviceContentContainer{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 5rem 0;
}
/* =========================================================Mobile */
@media screen and (max-width:768px){
    .serviceLanding figure{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .serviceLanding figure img{
        width: 400%;
    }
}