.serviceTab{
    width: 100%;
    height: 400px;
    /* height: 100%; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
    border-radius: 50px;
    padding: 2rem 1.5rem;
    margin: 2rem 0;
    z-index: 1;
    position: relative;
}
.serviceTabCol{
    width: 30%;
    height: 100%;
    display: flex;
    justify-content:flex-end;
    align-items: flex-start;
    flex-direction: column;
}
.serviceTabImage img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    mix-blend-mode: darken;
    /* transition: all 1s; */
}
.serviceTabCol article header h3{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s6);
    color: var(--dark);
    margin-bottom: 1.5rem;
    transition: all 1s;
}
.serviceTabCol article p{
    font-family: var(--f2);
    font-weight: var(--w1);
    font-size: var(--s3);
    color: var(--dark);
    margin-bottom: 1.5rem;
    transition: all 1s;
}
.serviceTabCol article{
    margin-bottom: auto;
    padding: 1.5rem 0 2rem 0;
}
.serviceTabList{
    justify-content: space-between;
}
.serviceTabContext{
    padding-bottom: 1.5rem;
}
.serviceTabCol li{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s5);
    color: var(--light);
    list-style-type: none;
    padding: .5rem 0;
    transition: all 1s;
    cursor: pointer;
}
.serviceTabTitle{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s7);
    color: var(--dark);
    padding-left: 2rem;   
}

/* =========================================================Mobile */
@media screen and (max-width:768px) {
    .serviceTabTitle{
        display: none;
    }
    .serviceTab{
        height: auto;
        flex-direction: column;
        padding-bottom: 4rem;
    }
    .serviceTabCol{
        width: 100%;
    }
}