/* ============================ Call Btn ====================== */
.actionBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    text-decoration: none;
    cursor: pointer;
    height: 40px;
    border-radius: 25px;
    background-color: var(--gold);
    transition: all .5s;
}
.toCall{
    padding: .25rem 1rem .25rem .25rem;
}
.toCall img{
    width: 2rem;
}
.actionBtn button{
    font-size: var(--s2);
    font-family: var(--f2);
    font-weight: var(--w3);
    color: var(--dark);
    text-decoration: none;
    background: transparent;
    border: none;
}
.actionBtn:hover>button{
    color: var(--white);
}
/* ============================ Form Btn ====================== */
.actionBtn p{
    font-size: var(--s5);
    font-family: var(--f1);
    font-weight: var(--w4);
    color: var(--dark);
    text-decoration: none;
    transition: all .5s;
}
.toForm{
    padding: .15rem 1rem;
    width: 180px;
}
.actionBtn:hover{
    padding-right: .5rem;
    gap: 1.5rem;
}
.actionBtn:hover>p{
    color: var(--white);
}
/* ========================== Testimonial Card ================ */
.testContainer{
    width: 240px; 
    height: 380px;
    border-radius: 24px;
    padding: 2rem 1.5rem;
    margin: 1.5rem 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    background-color: var(--white);
    transition: all 1s;
    margin-left: 20px;
}
.testContainer:hover{
    border: 1px solid var(--dark);
    box-shadow: 0 .5px 2px .5px var(--dark);
    mix-blend-mode: overlay;
}
.testContainer p{
    margin-bottom: auto;
    font-size: var(--s3);
    font-family: var(--f2);
    font-weight: var(--w1);
    color: var(--dark);
}
.testContainer h3{
    font-size: var(--s5);
    font-family: var(--f1);
    font-weight: var(--w1);
    color: var(--dark);
    line-height: 32px;
    text-align: left;
}
.testContainer h4{
    font-size: var(--s2);
    font-family: var(--f2);
    font-weight: var(--w1);
    color: var(--gold);
}
/* ============================== Service Container ====================*/
.serviceContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 4rem;
}
.serviceDirection{
    flex-direction: row;
}
.serviceDirectionrev{
    flex-direction: row-reverse;
}
.serviceContainer main{
    width: 40%;
}
.serviceContainer main h1{
    font-size: var(--s7);
    font-family: var(--f1);
    font-weight: var(--w1);
    color: var(--dark);
    margin-bottom: 1.5rem;
}
.serviceContainer main p{
    font-size: var(--s3);
    font-family: var(--f2);
    font-weight: var(--w1);
    color: var(--dark);
}
.serviceContainer figure{
    width: 40%;
}
.serviceContainer figure img{
    width: 100%;
    mix-blend-mode: darken;
}
/* =========================================================Mobile */
@media screen and (max-width:768px) {
    .serviceContainer{
        flex-direction: column-reverse;
    }
    .serviceContainer main{
        width: 100%;
        margin-top: 1rem;
    }
    .serviceContainer figure{
        width: 100%;
    }
    .serviceContainer main h1{
        font-size: var(--s5);
        margin-bottom: .5rem;
    }
    .serviceContainer main p{
        font-size: var(--s2);
    }
}
/* ================================ Article Card =================================== */
.articalContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;   
    align-items: flex-start;
    padding-bottom: 4rem;
    margin-bottom: 3rem;
    border-bottom: 1.5px solid var(--white);
}
.articalContainer figure{
    width: 55%;
    height: 450px;
    overflow: hidden;
    border-radius: 24px;
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.articalContainer figure img{
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.articalContainer main{
    width: 35%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.articalContainer main h1{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s7);
    color: var(--dark);
    margin-bottom: 1rem;
}
.articalContainer summary p{
    font-family: var(--f2);
    font-weight: var(--w1);
    font-size: var(--s3);
    color: var(--dark);
    line-height: 24px;
    text-align: justify;
}
.articalContainer summary{
    width: 100%;
    margin-bottom: 1.5rem;
    position: relative;
}
.articalContainer summary input{
    position: absolute;
    right: 0;
    padding: .25rem;
    cursor: pointer;
}
.truncate{
    display: -webkit-box;
    -webkit-line-clamp: 12;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.articalContainer summary input{
    appearance: none;
}
.articalContainer summary input::before{
    content: "Read More";
    color: var(--read);
    font-family: var(--f2);
    font-weight: var(--w1);
    font-size: var(--s2);
}
.articalContainer summary input:checked::before{
    content: "Read Less";
}
.articalContainer aside{
    width: 100%;
}
.articalContainer aside h3{
    font-family: var(--f2);
    font-weight: var(--w3);
    font-size: var(--s2);
    color: var(--dark);
    margin-bottom: .5rem;
}
.asidefooter{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: .5rem;
}
.asidefooter img{
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.asidefooter p{
    margin-left: auto;
    font-family: var(--f2);
    font-weight: var(--w1);
    font-size: var(--s3);
    color: var(--dark);
}
/* =========================================================Mobile */
@media screen and (max-width:768px) {
    .articalContainer{
        flex-direction: column;
        border-bottom: 2px solid var(--dark);
    }
    .articalContainer figure{
        width: 100%;
    }
    .articalContainer main{
        width: 100%;
    }
    .articalContainer figure{
        margin-bottom: 2rem;
    }
    .articalContainer main h1{
        font-size: var(--s6);
        margin-bottom: .5rem;
    }
    .articalContainer summary p{
        font-size: var(--s2);
    }
}