.aboutLanding{
    width: 100%;
    height: 600px;
    position: relative;
    top: 0;
    left: 0;
    margin-top: 1rem;
}
.aboutMainImage{
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
}
.aboutMainMeena{
    height: 95%;
    object-fit: contain;
    object-position: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}
.aboutMainDecor{
    height: 60%;
    object-fit: contain;
    object-position: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 0;
}
.aboutSubImages{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.aboutSubImages div{
    width: 50%;
    height: 100%;
    position: relative;
}
.aboutsub{
    width: 120px;
    height: 120px;
    position: absolute;
    border: 3px solid var(--gold);
    border-radius: 50%;
    padding: .50rem;
}
.one{
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.two{
    top: 50%;
    left: 30%;
    transform: translate(-50%, -50%);
}
.three{
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.four{
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.five{
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
}
.six{
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.aboutsub img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}

/* =========================================================Mobile */
@media screen and (max-width:768px) {
    .aboutLanding{
        display: flex;
        flex-direction: column;
        height: auto;
        position: static;
    }
    .aboutMainImage{
        position: static;
        height: 500px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .aboutMainMeena{
        width: auto;
        height: 100%;
        position: static;
        transform: translateX(0%);
    }
    .aboutMainDecor{
        width: 80%;
        position: absolute;
    }
    .aboutSubImages{
        position: relative;
        display: flex;
        flex-direction: column;
    }
    .aboutSubImages div{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
    }
    .aboutsub{
        position: static;
        transform: translate(0, 0); 
        width: 100px;
        height: 100px;
    }
}

/* ==================================== About Content ==========================*/
.aboutContent{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6rem;
}
.aboutContent main{
    width: 60%;
    padding: 2rem;
    height: 800px;
}
.aboutContent main h1{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s8);
    color: var(--dark);
    margin-bottom: 1.5rem;
}
.aboutContent main p{
    font-family: var(--f2);
    font-weight: var(--w1);
    font-size: var(--s3);
    color: var(--dark);
    margin-bottom: 1.5rem;
}
.aboutContent aside{
    width: 25%;
    position: relative;
    margin-left: 2rem;
}
.aboutContent aside img{
    height: 700px;
    position: absolute;
    top: 50px;
}
/* =========================================================Mobile */
@media screen and (max-width:768px) {
    .aboutContent{
        width: 100%;
        flex-direction: column;
    }
    .aboutContent main{
        width: 100%;
        height: auto;
        padding: 1rem;
    }
    .aboutContent main h1{
        font-size: var(--s6);
        margin-bottom: .5rem;
    }
    .aboutContent main p{
        font-size: var(--s2);
    }
    .aboutContent aside{
        display: none;
    }
}

/* ============================ Link ======================== */
.aboutToLink{
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 5rem;
    padding-left: 2rem;
    margin-bottom: 5rem;
}
.aboutDummy{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s6);
    color: var(--dark);
}
.aboutTestimonial{
    margin-bottom: 5rem;
}
/* =========================================================Mobile */
@media screen and (max-width:768px) {
    .aboutToLink{
        flex-direction: column;
        margin-bottom: 1rem;
        gap: 1rem;
        padding-left: 1rem;
    }
    .aboutDummy{
        font-size: var(--s5);
    }
}