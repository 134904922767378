.addArtForm{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 2rem;
}
.addArtForm h1{
    width: 100%;
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s8);
    color: var(--dark);
    margin-bottom: 2rem;
    text-align: right;
}
.articleInput{
    width: 60%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 3rem;
    margin-bottom: 1rem;
    position: relative;
}
.articleInput label{
    font-family: var(--f2);
    font-weight: var(--w3);
    font-size: var(--s3);
    color: var(--dark);
    width: 20%;
}
.articleInput input, .articleInput textarea{
    width: 100%;
    padding: .5rem;
    border-radius: .5rem;
    background-color: var(--white);
    border: none;
    outline: none;
    color: var(--dark);
}
.articleInput input[type='submit']{
    margin-top: 1rem;
    width: 20%;
    background-color: var(--gold);
    color: var(--dark);
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s3);
    margin-left: auto;
}
/* ============================================================= */
/* .addArtForm{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2rem;
}
.artFormCol{
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.articleInputImage{
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.articleInputImage img{
    width: 50%;
    object-fit: contain;
} */