@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root{
  --dark:#562500;
  --light:#F6D69D;
  --gold:#FF9A4D;
  --white:#FFF3EA;
  --input:#FFC497;
  --read:#FF6F00;
  --notify:#A54800;

  --f1:  "Crimson Text", serif;
  --f2: "Roboto", sans-serif;

  --w1: 400;
  --w2: 500;
  --w3: 600;

  --s1: .75rem; /* 12px */
  --s2: .875rem; /* 14px */
  --s3: 1rem; /* 16px */
  --s4: 1.35rem; /* 20px */
  --s5: 1.5rem; /* 24px */
  --s6: 1.75rem; /* 28px */
  --s7: 2.25rem; /* 36px */
  --s8: 2.5rem; /* 40px */
  --s9: 3.5rem; /* 57px */
  --s10: 4rem; /* 64px */
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#root{
  width: 100%;
  height: auto;
  overflow-x: hidden;
  background-color: #F6D69D;
}
.wrapper{
  width: 80%;
  margin-left: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.cms{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem;
  min-height: 100vh;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: var(--light);
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--gold); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--dark); 
}

/* =========================================================Mobile */
@media screen and (max-width:768px) {
  .wrapper{
    width: 90%;
    margin-left: 5%;
    /* margin-top: 5rem; */
  } 
}