.conditionWrapper, .privacyWrapper{
    position: relative;
    min-height: 60vh;
}
.conditionWrapper section, .privacyWrapper section{
    margin: 2rem 0;
    padding: 1rem;
}
.conditionWrapper section h1, .privacyWrapper section h1{
    text-align: center;
    margin-bottom: 2rem;
    color: var(--dark);
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s7);
}
.conditionWrapper section ol li{
    color: var(--dark);
    font-family: var(--f2);
    font-weight: var(--w1);
    font-size: var(--s2);
    margin-bottom: 1rem;
}
.conditionWrapper section ol li header{
    font-weight: var(--w2);
    font-size: var(--s3);
    margin-bottom: .5rem;
}
.conditionWrapper section ol li main p{
    margin-bottom: .5rem;
}
.termsDecor{
    width: 100vw;
    height: 100vh;
    position: absolute;
    overflow: hidden;
    pointer-events: none; 
    bottom: 0;  
}
.termsDecor img{
    width: 65%;
    position: absolute;
    right: -25%;
    bottom: -50%;
    opacity: .3;
    z-index: 0;
}
/* To change style of ordered list number */
.conditionWrapper section ol { 
    counter-reset: item;
    list-style-type: none;
} 
.conditionWrapper section ol li { 
    counter-increment: item;
} 
.conditionWrapper section ol li::marker { 
    content: counter(item) ". ";
    color: var(--dark); 
    font-weight: var(--w3);
} 
.conditionWrapper section ol li main p span{
    font-weight: var(--w3);
}

/* ==========================================================Privacy */
.privacyWrapper section article{
    color: var(--dark);
    font-family: var(--f2);
    font-weight: var(--w1);
    font-size: var(--s2);
    margin-bottom: 1rem;
}
.privacyWrapper section article p{
    padding-left: 1rem;
} 
.privacyWrapper section article header{
    font-weight: var(--w2);
    font-size: var(--s3);
    margin-bottom: .5rem;
}
.privacyWrapper section article main{
    padding-left: 1rem;
}
.privacyWrapper section article main h2{
    font-weight: var(--w2);
    font-size: var(--s2);
    margin-bottom: .25rem;
}
.privacyWrapper section article main p{
    padding-left: 1rem;
}

/* ==================================================refund page */
.refundPAge{
    color: var(--dark);
    font-family: var(--f2);
    font-weight: var(--w1);
    font-size: var(--s2);
    margin-bottom: 1rem;
}