.homeTestimonial{
    width: 100vw;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2rem 0rem 2rem 5rem;
    gap: 2rem;
}
.homeTestimonial figure{
    width: 500px;
    height: 550px;
    display: flex;
    align-items: center;  
}
.homeTestimonial figure img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}
.homeTestimonial aside{
    width: 70%;
}
.testTitle{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s7);
    color: var(--dark);   
}
.testSlider{
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    padding: .5rem 1.5rem .5rem 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    scrollbar-width: none; 
    -ms-overflow-style: none;
    overflow: hidden;
}
.testSlider::-webkit-scrollbar {
    display: none;
  }
.Slide{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: all 1s ease;
}
.testSlideBtn{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.5rem;
    padding-left: 2rem;
}
.testSlideBtn figure{
    height: 100%;
    width: 50px;
    cursor: pointer;
}
.testSlideBtn figure img{
    height: 100%;
}
.btnClick:active{
    border: 1px solid var(--dark);
    border-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.arrowFade{
    cursor: context-menu !important;
    mix-blend-mode: darken;
}

/* =========================================================Mobile */
@media screen and (max-width:768px) {
    .homeTestimonial{
        flex-direction: column-reverse;
        padding: 2rem 0rem 2rem 0rem;
        position: relative;
        margin-bottom: 50%;
    }
    .testTitle{
        font-family: var(--f1);
        font-weight: var(--w1);
        font-size: var(--s6);
        color: var(--dark);
        margin-left: 2rem;   
    }
    .homeTestimonial aside{
        width: 100%;
    }
    .testDecor{
        position: absolute;
        width: 90% !important;
        right: -35%;
        bottom: -40%;
    }
}