.landingContainer{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 1rem 5rem;
}
.landingContainer aside{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;
    background-color: var(--gold);
    padding: .25rem 2rem;
    border-radius: 1rem;
    margin-bottom: 2rem;
}
.landingContainer aside h2{
    margin-right: auto;
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s7);
    color: var(--white);
}
.landingContainer aside button{
    padding: .25rem .5rem;
    border-radius: 5px;
    background-color: var(--light);
    border: none;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    cursor: pointer;
    font-family: var(--f2);
    font-weight: var(--w2);
    font-size: var(--s3);
    color: var(--dark);
}
.landingContainer aside button:active{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.landingContainer main{
    width: 100%;
    padding: .25rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.landingContainer main table{
    width: 70%;
    border-collapse: collapse;
    table-layout: fixed;
    border-spacing: 10px;
    font-family: var(--f2);
    font-weight: var(--w2);
    font-size: var(--s3);
    color: var(--dark);
}
.landingContainer main table th{
    border-bottom: 3px solid var(--dark);
}
.landingContainer main table td{
    text-align: center;
    /* border-bottom: 2px solid var(--dark); */
    padding: .25rem 0;
}

.landingContainer main table td button{
    /* padding: .25rem .5rem; */
    width: 40%;
    border-radius: 5px;
    background-color: var(--light);
    border: none;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    cursor: pointer;
    font-family: var(--f2);
    font-weight: var(--w2);
    font-size: var(--s3);
    color: var(--dark);
}
/* =============================================================================================================================================== */

.removeWindow{
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    top: 0;
}
.rmBG{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .5;
    z-index: 10;
}
.removeContainer{
    padding: 2rem;
    border-radius: 25px;
    background-color: var(--light);
    z-index: 11;
}
.removeContainer p{
    font-family: var(--f2);
    font-weight: var(--w2);
    font-size: var(--s3);
    color: var(--dark);
    margin-bottom: 1rem;
}
.removeOption{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.removeOption button{
    width: 50px;
    padding: .15rem;
    font-family: var(--f2);
    font-weight: var(--w2);
    font-size: var(--s3);
    color: var(--white);
    border-radius: 10px;
    background-color: var(--gold);
    border: 1px solid var(--dark);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.removeOption button:active{
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}