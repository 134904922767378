.dashContainer{
    width: 100%;
    height: calc(100vh - 15rem) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column;
}
.dashCountContainer{
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 2rem;
}
.dashBtnContainer{
    width: 30%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
}
.dashCount{
    background-color: var(--gold);
    padding: .25rem 2rem;
    border-radius: 1rem;
}
.dashCount h1{
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s10);
    color: var(--white);
    text-align: center;
}
.dashCount p{
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s5);
    color: var(--white);
    text-align: center;
}
.dashBtnContainer button{
    padding: .25rem .5rem;
    border-radius: 5px;
    background-color: var(--gold);
    border: none;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    cursor: pointer;
    font-family: var(--f2);
    font-weight: var(--w2);
    font-size: var(--s3);
    color: var(--dark);
    margin-bottom: 2rem;
}